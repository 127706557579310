import { DestroyRef, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { TranslateService } from '@ngx-translate/core';
import { TuiDialogService } from '@taiga-ui/core';
import { TUI_PROMPT } from '@taiga-ui/kit';

export function injectShowTextTuiPrompt() {
  const translate = inject(TranslateService);
  const tuiDialogService = inject(TuiDialogService);
  const destroyRef = inject(DestroyRef);

  return (label: string, confirmButton: string, cancelButton?: string) =>
    tuiDialogService
      .open<boolean>(TUI_PROMPT, {
        label: translate.instant(label),
        data: {
          yes: translate.instant(confirmButton),
          no: translate.instant(cancelButton ? cancelButton : 'common.cancel')
        }
      })
      .pipe(takeUntilDestroyed(destroyRef));
}
