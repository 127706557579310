import { inject } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { ActivatedRoute } from '@angular/router';
import { map } from 'rxjs';

export function injectRouteData<T>(key: string) {
  const route = inject(ActivatedRoute);

  return toSignal(route.data.pipe(map((data) => data[key] as T)));
}
