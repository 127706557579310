/* eslint-disable prefer-rest-params */
/* eslint-disable no-console */

export function createSimpleLogger() {
  return {
    debug: (...messages: Parameters<Console['log']>) =>
      console.log(`%c${messages}`, `font-size: 12px; color: #2E86C1`),
    info: (...messages: Parameters<Console['log']>) =>
      console.log(`%c${messages}`, `font-size: 12px; color: #48C9B0`),
    warning: (...messages: Parameters<Console['log']>) =>
      console.log(`%c${messages}`, `font-size: 12px; color: #F4D03F`),
    error: (...messages: Parameters<Console['log']>) =>
      console.log(`%c${messages}`, `font-size: 12px; color: #E74C3C`)
  };
}

export function createContextLogger(context: string) {
  return {
    debug: console.log.bind(
      null,
      `[${context}:DBG]`,
      ...[...arguments].slice(1)
    ),
    info: console.log.bind(
      null,
      `[${context}:INF]`,
      ...[...arguments].slice(1)
    ),
    warning: console.warn.bind(
      null,
      `[${context}:WARN]`,
      ...[...arguments].slice(1)
    ),
    error: console.error.bind(
      null,
      `[${context}:ERR]`,
      ...[...arguments].slice(1)
    )
  };
}
