import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';

export interface LangChangeEvent {
  lang: string;
  translations: any;
}

export interface Translator {
  translate(key: string, params?: Record<string, any>): Observable<string>;
  translateSync(key: string, params?: Record<string, any>): string;
  lang$: Observable<LangChangeEvent>;
  translations$: Observable<LangChangeEvent>;
  defaultLang$: Observable<LangChangeEvent>;
  lang: string;
  get langs(): string[];
  set langs(langs: string[]);
  use(lang: string): void;
  getParsedResult(tranlations: any, key: any, interpolateParams?: object): any;
}

export const TRANSLATOR = new InjectionToken<Translator>('Translator');
