import { DestroyRef, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { TranslateService } from '@ngx-translate/core';
import { TuiAlertOptions, TuiAlertService } from '@taiga-ui/core';

/**
 * Можно спокойно делать `subscribe`
 */
export function injectShowTextTuiAlert() {
  const tuiAlert = inject(TuiAlertService);
  const translate = inject(TranslateService);
  const destroyRef = inject(DestroyRef);

  return (message: string, status: TuiAlertOptions<any>['status']) =>
    tuiAlert
      .open(translate.instant(message), { status })
      .pipe(takeUntilDestroyed(destroyRef));
}
