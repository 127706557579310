import { ChangeDetectorRef, inject, ViewRef } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';

export function untilDestroyed<T>() {
  const subject = new Subject<void>();

  const viewRef = inject(ChangeDetectorRef) as ViewRef;

  queueMicrotask(() =>
    viewRef.onDestroy(() => {
      subject.next();
      subject.complete();
    })
  );

  return takeUntil<T>(subject.asObservable());
}
