import { TranslateService } from '@ngx-translate/core';
import cronsture from 'cronstrue';
// TODO maybe we can remove this and use only cronsture. this import costs 190kb
import locale from 'cronstrue/i18n';
import {
  getDate,
  getHours,
  getMinutes,
  getMonth,
  getSeconds,
  getYear
} from 'date-fns';

export class Cron {
  private _seconds = '*'; // 0-59
  private _minutes = '*'; // 0-59
  private _hours = '*'; // 0-23
  private _dayOfMonth = '*'; // 0-31
  private _month = '*'; // 0-11 || [JAN, FEB, MAR, APR, MAY, JUN, JUL, AUG, SEP, OCT, NOV, DEC]
  private _dayOfWeek = '*'; // 1-7 (1 = Sunday) || [SUN, MON, TUE, WED, THU, FRI, SAT]
  private _year = '*'; // 2021...

  constructor() {
    this.parseDate(new Date());
  }

  parseDate(date: Date, utc0?: boolean): this {
    this.setSeconds(utc0 ? date.getUTCSeconds() : getSeconds(date));
    this.setMinutes(utc0 ? date.getUTCMinutes() : getMinutes(date));
    this.setHours(utc0 ? date.getUTCHours() : getHours(date));
    this.setDayOfMonth(
      utc0 ? date.getUTCDate().toString() : getDate(date).toString()
    );
    this.setMonth(
      utc0
        ? (date.getUTCMonth() + 1).toString()
        : (getMonth(date) + 1).toString()
    );
    this.setDayOfWeek('?');
    this.setYear(
      utc0 ? date.getUTCFullYear().toString() : getYear(date).toString()
    );
    return this;
  }

  parseCron(cron: string): this {
    const split = cron.split(' ');
    if (split.length > 7) {
      throw new Error('Can not parse cron with more than 7 symbols');
    }
    [
      this._seconds,
      this._minutes,
      this._hours,
      this._dayOfMonth,
      this._month,
      this._dayOfWeek,
      this._year
    ] = split;
    return this;
  }

  setSeconds(seconds: number): this {
    this._seconds = seconds.toString();
    return this;
  }

  setMinutes(minutes: number): this {
    this._minutes = minutes.toString();
    return this;
  }

  setHours(hours: number): this {
    this._hours = hours.toString();
    return this;
  }

  setDayOfMonth(dom: string): this {
    this._dayOfMonth = dom;
    return this;
  }

  setMonth(month: string): this {
    this._month = month;
    return this;
  }

  setDayOfWeek(dow: string): this {
    this._dayOfWeek = dow;
    return this;
  }

  setYear(year: string): this {
    this._year = year;
    return this;
  }

  expression(): string {
    return `${this._seconds} ${this._minutes} ${this._hours} ${this._dayOfMonth} ${this._month} ${this._dayOfWeek} ${this._year}`;
  }

  describe(translateService?: TranslateService): string {
    if (translateService) {
      return locale.toString(this.expression(), {
        locale: translateService.currentLang,
        use24HourTimeFormat: true,
        verbose: true,
        tzOffset: -new Date().getTimezoneOffset() / 60
      });
    }
    return cronsture.toString(this.expression(), {
      tzOffset: -new Date().getTimezoneOffset() / 60
    });
  }
}
