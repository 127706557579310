import { Pipe, PipeTransform, inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { formatDistance } from 'date-fns';
import enUS from 'date-fns/locale/en-US';
import ru from 'date-fns/locale/ru';

@Pipe({
  name: 'knkDuration',
  standalone: true
})
export class FormatDurationPipe implements PipeTransform {
  private readonly translate = inject(TranslateService);

  transform(firstDate: string | Date, secondDate: string | Date): string {
    let date1 = firstDate;
    let date2 = secondDate;
    if (typeof firstDate === 'string') {
      date1 = new Date(firstDate);
    }
    if (typeof secondDate === 'string') {
      date2 = new Date(secondDate);
    }

    return formatDistance(date1 as Date, date2 as Date, {
      includeSeconds: true,
      locale: this.translate.currentLang === 'ru' ? ru : enUS
    });
  }
}
