import {
  Directive,
  HostBinding,
  HostListener,
  inject,
  input
} from '@angular/core';
import { Router } from '@angular/router';
import { INSTANCE_CODE } from '@konnektu/tokens';

@Directive({
  selector: '[knkTenantRouterLink]',
  standalone: true
})
export class TenantRouterLinkDirective {
  private readonly tenantCode = inject(INSTANCE_CODE);
  private readonly router = inject(Router);

  knkTenantRouterLink = input<any[] | string | undefined | null>();

  @HostBinding('attr.href')
  get href(): string {
    const link = this.knkTenantRouterLink();
    if (!link) return '';

    if (Array.isArray(link)) {
      return `/${this.tenantCode}/${link.join('/')}`;
    }
    return `/${this.tenantCode}${link}`;
  }

  @HostListener('click', ['$event'])
  onClick(event: MouseEvent): void {
    event.preventDefault();
    event.stopPropagation();

    const link = this.knkTenantRouterLink();
    if (!link) return;

    if (Array.isArray(link)) {
      this.router.navigate([`/${this.tenantCode}`, ...link]);
    } else {
      this.router.navigateByUrl(`/${this.tenantCode}${link}`);
    }
  }
}
