import { Pipe, PipeTransform, inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { format } from 'date-fns';
import enUS from 'date-fns/locale/en-US';
import ru from 'date-fns/locale/ru';
import { DATE_PIPE_DEFAULT_OPTIONS } from './constants';
import { DATE_PIPE_OPTIONS } from './date-pipe-options.token';

export interface DatePipeOptions {
  format?: string;
}

@Pipe({
  name: 'knkDate',
  standalone: true
})
export class DatePipe implements PipeTransform {
  private readonly translate = inject(TranslateService);

  private readonly options = inject<DatePipeOptions>(DATE_PIPE_OPTIONS, {
    optional: true
  });

  transform(
    value: Date | number | string,
    options: DatePipeOptions | null = this.options
  ): string {
    return format(
      new Date(value),
      options?.format ?? DATE_PIPE_DEFAULT_OPTIONS.format,
      {
        locale: this.translate.currentLang === 'ru' ? ru : enUS
      }
    );
  }
}
