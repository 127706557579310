import * as i0 from '@angular/core';
import { Component, ChangeDetectionStrategy, Inject, NgModule } from '@angular/core';
import * as i4 from '@taiga-ui/cdk';
import { TUI_IS_MOBILE, TuiAutoFocusModule } from '@taiga-ui/cdk';
import * as i1 from '@taiga-ui/core';
import { TuiAppearance, TuiButtonModule } from '@taiga-ui/core';
import { TUI_PROMPT_WORDS } from '@taiga-ui/kit/tokens';
import * as i2 from '@tinkoff/ng-polymorpheus';
import { POLYMORPHEUS_CONTEXT, PolymorpheusComponent, PolymorpheusModule } from '@tinkoff/ng-polymorpheus';
import * as i3 from '@angular/common';
import { CommonModule } from '@angular/common';
import * as i5 from 'rxjs';

// TODO: Rename to tui-confirm in 4.0
function TuiPromptComponent_div_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "div", 2);
  }
  if (rf & 2) {
    const text_r1 = ctx.polymorpheusOutlet;
    i0.ɵɵproperty("innerHTML", text_r1, i0.ɵɵsanitizeHtml);
  }
}
function TuiPromptComponent_div_1_Template(rf, ctx) {
  if (rf & 1) {
    const _r2 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "div", 3)(1, "button", 4);
    i0.ɵɵlistener("click", function TuiPromptComponent_div_1_Template_button_click_1_listener() {
      i0.ɵɵrestoreView(_r2);
      const ctx_r2 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r2.context.completeWith(false));
    });
    i0.ɵɵtext(2);
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(3, "button", 5);
    i0.ɵɵlistener("click", function TuiPromptComponent_div_1_Template_button_click_3_listener() {
      i0.ɵɵrestoreView(_r2);
      const ctx_r2 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r2.context.completeWith(true));
    });
    i0.ɵɵtext(4);
    i0.ɵɵelementEnd()();
  }
  if (rf & 2) {
    const words_r4 = ctx.ngIf;
    const ctx_r2 = i0.ɵɵnextContext();
    i0.ɵɵadvance();
    i0.ɵɵproperty("appearance", ctx_r2.appearance);
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate1(" ", (ctx_r2.context.data == null ? null : ctx_r2.context.data.no) || words_r4.no, " ");
    i0.ɵɵadvance(2);
    i0.ɵɵtextInterpolate1(" ", (ctx_r2.context.data == null ? null : ctx_r2.context.data.yes) || words_r4.yes, " ");
  }
}
class TuiPromptComponent {
  constructor(words$, context, isMobile) {
    this.words$ = words$;
    this.context = context;
    this.isMobile = isMobile;
  }
  get appearance() {
    return this.isMobile ? TuiAppearance.Secondary : TuiAppearance.Flat;
  }
}
TuiPromptComponent.ɵfac = function TuiPromptComponent_Factory(t) {
  return new (t || TuiPromptComponent)(i0.ɵɵdirectiveInject(TUI_PROMPT_WORDS), i0.ɵɵdirectiveInject(POLYMORPHEUS_CONTEXT), i0.ɵɵdirectiveInject(TUI_IS_MOBILE));
};
TuiPromptComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: TuiPromptComponent,
  selectors: [["tui-prompt"]],
  decls: 3,
  vars: 4,
  consts: [["class", "t-content", 3, "innerHTML", 4, "polymorpheusOutlet"], ["class", "t-buttons", 4, "ngIf"], [1, "t-content", 3, "innerHTML"], [1, "t-buttons"], ["size", "m", "tuiButton", "", "type", "button", 1, "t-button", 3, "click", "appearance"], ["size", "m", "tuiAutoFocus", "", "tuiButton", "", "type", "button", 1, "t-button", 3, "click"]],
  template: function TuiPromptComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵtemplate(0, TuiPromptComponent_div_0_Template, 1, 1, "div", 0)(1, TuiPromptComponent_div_1_Template, 5, 3, "div", 1);
      i0.ɵɵpipe(2, "async");
    }
    if (rf & 2) {
      i0.ɵɵproperty("polymorpheusOutlet", ctx.context.data == null ? null : ctx.context.data.content);
      i0.ɵɵadvance();
      i0.ɵɵproperty("ngIf", i0.ɵɵpipeBind1(2, 2, ctx.words$));
    }
  },
  dependencies: [i1.TuiButtonComponent, i2.PolymorpheusOutletDirective, i3.NgIf, i4.TuiAutoFocusDirective, i3.AsyncPipe],
  styles: [".t-content[_ngcontent-%COMP%]:not(:empty){margin-bottom:.875rem}.t-buttons[_ngcontent-%COMP%]{display:flex;justify-content:flex-end;flex-wrap:wrap-reverse;margin:.375rem -.375rem -.375rem}.t-button[_ngcontent-%COMP%]{margin:.375rem;white-space:nowrap}tui-root._mobile[_nghost-%COMP%]   .t-button[_ngcontent-%COMP%], tui-root._mobile   [_nghost-%COMP%]   .t-button[_ngcontent-%COMP%]{flex:1}"],
  changeDetection: 0
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiPromptComponent, [{
    type: Component,
    args: [{
      selector: 'tui-prompt',
      templateUrl: './prompt.template.html',
      styleUrls: ['./prompt.style.less'],
      changeDetection: ChangeDetectionStrategy.OnPush
    }]
  }], function () {
    return [{
      type: i5.Observable,
      decorators: [{
        type: Inject,
        args: [TUI_PROMPT_WORDS]
      }]
    }, {
      type: undefined,
      decorators: [{
        type: Inject,
        args: [POLYMORPHEUS_CONTEXT]
      }]
    }, {
      type: undefined,
      decorators: [{
        type: Inject,
        args: [TUI_IS_MOBILE]
      }]
    }];
  }, null);
})();
const TUI_PROMPT = new PolymorpheusComponent(TuiPromptComponent);
class TuiPromptModule {}
TuiPromptModule.ɵfac = function TuiPromptModule_Factory(t) {
  return new (t || TuiPromptModule)();
};
TuiPromptModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: TuiPromptModule
});
TuiPromptModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  imports: [[CommonModule, PolymorpheusModule, TuiButtonModule, TuiAutoFocusModule]]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiPromptModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule, PolymorpheusModule, TuiButtonModule, TuiAutoFocusModule],
      declarations: [TuiPromptComponent],
      exports: [TuiPromptComponent]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { TUI_PROMPT, TuiPromptComponent, TuiPromptModule };
